<template>
  <div>
    <v-form>
      <p class="mb-3">
        As part of your preparation for the lab, you must be appropriately dressed. Select the
        <b>TRUE</b> statements about what is appropriate in the lab. Choose all that apply.
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="my-0 pl-6">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'I should not wear a top that has long loose sleeves.',
          value: 'looseSleeves',
        },
        {
          text: 'I must always wear safety goggles when I am in the lab.',
          value: 'goggles',
        },
        {
          text: 'In the summer, I can wear sandals in the lab because it is hot outside.',
          value: 'sandals',
        },
        {
          text: 'I should always wear closed shoes in the lab.',
          value: 'closedShoes',
        },
        {
          text: 'It is okay for me to wear a "short" t-shirt (a midriff shirt, where part of my stomach area is exposed) in the lab.',
          value: 'cropTop',
        },
        {
          text: 'If I want to, I can wear an expensive suit to the lab.',
          value: 'suit',
        },
        {
          text: 'It is not a hazard for me to wear long, dangling earring or hair accessories, since my face will never be close to any chemicals.',
          value: 'accessories',
        },
        {
          text: "If I have long hair, I don't need to tie it back as long as the strap from my safety goggles is on top of my hair at the back of my head.",
          value: 'longHair',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
